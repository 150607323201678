import React, { Component } from "react";
import axios from "axios";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import IntlMessages from "../../components/utility/intlMessages";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import {
  IconButtons,
  TopbarDropdown,
  UserInformation,
  SettingsList,
  Icon,
} from "./topbarDropdown.style";
import authAction from "../../redux/auth/actions";
import updateUserName from "../../redux/dashboard/actions";
import Image from "../../images/userCirle2Img.png";
import { logigloUrl } from "../Config";
import { GoFileSubmodule } from "react-icons/go";
import { BsSubstack } from "react-icons/bs";
import { RiDraftLine } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";
import { PiUserCircleThin } from "react-icons/pi";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilInput,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
// import "@coreui/coreui/dist/css/coreui.min.css";
import { IoSettingsOutline } from "react-icons/io5";
import { TfiWrite } from "react-icons/tfi";
import { BsPostcard } from "react-icons/bs";
import { TfiCommentAlt } from "react-icons/tfi";
import { IoLogOutOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";





const jwtToken = localStorage.getItem("token");
const { logout } = authAction;
const { updateName } = updateUserName;

class TopbarUser extends Component {
  state = {
    visible: false,
    anchorEl: null,
    isLoading: true,
    fetchBusinessAccountName: false, // Initial state for fetching personal account name
    accountType: null,
    addBusinessAccountStatus: false,
    accountId: null,
    submittedPostsCount: 0,
    draftedPostsCount : 0,
    pendingRepliesCount: 0,
    pendingQuotesCount: 0

  };

  componentDidMount() {
    // Fetch data initially when the component mounts
    let accountType = null;

    this.fetchUserData();

    this.fetchAddBusinessAccountStatus();

    


    // Check if token exists in localStorage
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      // Check if accountType exists in parsedToken
      if (parsedToken && parsedToken.accountType) {
        accountType = parsedToken.accountType;
      }
    }



  

    // Example: Update state with the retrieved accountType
    this.setState({ accountType });
    

    console.log(accountType, "acc_type");
  }


 

  fetchAddBusinessAccountStatus() {
    axios
      .get(`${logigloUrl}/getAddBusinessAccountStatus`)
      .then((response) => {
        // setLinkedInSignupStatus(response.data.linkedInSignupStatus === 1);
        const addBusinessAccountStatus =
          response.data.addBusinessAccountStatus === 1;
        // Update the state accordingly
        this.setState({ addBusinessAccountStatus });
        console.log(addBusinessAccountStatus, "li_status2");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchUserData() {
    // Fetch data initially when the component mounts

    let account_id;
    let accountId

    if (jwtToken) {
      const userAccount_id = localStorage.getItem("token");
      const tokenObject = JSON.parse(userAccount_id);

      account_id = tokenObject.accountId;
      accountId = tokenObject.accountId


      this.setState({ accountId });

      this.setState({ accountId }, () => {
        this.fetchSubmittedPosts(); // Ensure accountId is set before calling this function
        this.fetchDraftedPosts();
        this.fetchPendingReplies();
        this.fetchPendingQuotes();
      });

    }

    // Initially receiving account_id from URL
    // const params = new URLSearchParams(this.props.location.search).get(
    //   "account_id"
    // );

    // console.log(params, "paramparam");

    const fetchBusinessAccountName = this.state.fetchBusinessAccountName;

    this.setState({ isLoading: true });

    // Toggle the state for the next click
    this.setState({ fetchBusinessAccountName: !fetchBusinessAccountName });

    let apiEndpoint = fetchBusinessAccountName
      ? "getBussAccName"
      : "getContactFirstName";

    axios
      .get(`${logigloUrl}/${apiEndpoint}?account_id=${account_id}`)
      .then((response) => {
        if (fetchBusinessAccountName) {
          const { name } = response.data;
          console.log("Switched to Business account, Business Name:", name);
          // this.props.updateName(name, params);
          this.props.updateName(name, account_id);
          // Add your logic to handle the retrieved business account name
        } else {
          const { first_name } = response.data;
          console.log(
            "Switched to Personal account, Personal Acc Name:",
            first_name
          );
          // this.props.updateName(first_name, params);
          this.props.updateName(first_name, account_id);
          // Add your logic to handle the retrieved contact first name
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error("Error switching account:", error);
        // Handle the error
      });
  }


  fetchSubmittedPosts = () => {
    const { accountId } = this.state; // Destructure accountId from state

    console.log(accountId, "AccountAccount")
    
    if (!accountId) {
      console.warn("Account ID is not set");
      return; // Prevent the request if accountId is not available
    }
  
    axios
      .get(`${logigloUrl}/getSubbmittedPosts/${accountId}`)
      .then((response) => {
        console.log(response.data.length, "submitted_postsdddddd"); // Log the entire response data
        this.setState({ submittedPostsCount: response.data.length }); // Store the count of submitted posts
      })
      .catch((error) => {
        console.error("Error fetching submitted posts:", error);
      });
  };


  fetchPendingReplies = () => {
    const { accountId } = this.state; // Destructure accountId from state
  
    console.log(accountId, "AccountAccount");
  
    if (!accountId) {
      console.warn("Account ID is not set");
      return; // Prevent the request if accountId is not available
    }
  
    axios
      .get(`${logigloUrl}/replies/pending/${accountId}`)
      .then((response) => {
        console.log(response.data, "pendingReplies"); // Log the entire response data
        this.setState({ pendingRepliesCount: response.data.length }); // Store the pending replies in state
      })
      .catch((error) => {
        console.error("Error fetching pending replies:", error);
      });
  };


  fetchPendingQuotes = () => {
    const { accountId } = this.state; // Destructure accountId from state
  
    console.log(accountId, "AccountAccount");
  
    if (!accountId) {
      console.warn("Account ID is not set");
      return; // Prevent the request if accountId is not available
    }
  
    axios
      .get(`${logigloUrl}/quotes/pending/${accountId}`)
      .then((response) => {
        console.log(response.data, "pendingQuotes"); // Log the entire response data
        this.setState({ pendingQuotesCount: response.data.length }); // Store the pending replies in state
      })
      .catch((error) => {
        console.error("Error fetching pending replies:", error);
      });
  };



   // Function to fetch drafted posts
    fetchDraftedPosts = () => {
      const { accountId } = this.state;

      if (!accountId) {
        console.warn("Account ID is not set");
        return; // Prevent the request if accountId is not available
      }

    axios
      .get(`${logigloUrl}/getDraftedPosts/${accountId}`)
      .then((response) => {
        console.log(response.data, "drafted_posts");
        this.setState({ draftedPostsCount: response.data.length }); // Store the count of submitted posts
      })
      
      .catch((error) => {
        console.error(error);
      });
  };

  hide = () => {
    this.setState({ visible: false });
  };
  handleVisibleChange = () => {
    this.setState({
      visible: !this.state.visible,
      anchorEl: findDOMNode(this.button),
    });
  };

  handleAdminSettings = () => {
    this.hide();
  };

  handleUserSettings = () => {
    this.hide();
  };

  handleDrafts = () => {
    this.hide();
  };

  handleSubmittedPosts = () => {
    this.hide();
  };

  handleSubmittedReplies = () => {
    this.hide();
  };

  handleSubmittedQuotes = () => {
    this.hide();
  };

  handleFeedBack = () => {
    this.hide();
  };

  handleHelp = () => {
    this.hide();
  };

  handleSwitchAccount = () => {
    // sending account_id as params
    const params = this.props.account_id;
    // console.log(params, "pap")

    this.setState({ isLoading: true });

    // const params =  new URLSearchParams(this.props.location.search).get("account_id");
    const fetchBusinessAccountName = this.state.fetchBusinessAccountName;

    // Toggle the state for the next click
    this.setState({ fetchBusinessAccountName: !fetchBusinessAccountName });

    let apiEndpoint = fetchBusinessAccountName
      ? "getBussAccName"
      : "getContactFirstName";

    axios
      .get(`${logigloUrl}/${apiEndpoint}?account_id=${params}`)
      .then((response) => {
        if (fetchBusinessAccountName) {
          const { name } = response.data;
          console.log("Switched to Business account, Business Name:", name);
          // this.props.updateName(name, params);
          this.props.updateName(name, params);

          // Add your logic to handle the retrieved business account name
        } else {
          const { first_name } = response.data;
          console.log(
            "Switched to Personal account, Personal Acc Name:",
            first_name
          );
          // this.props.updateName(first_name, params);
          this.props.updateName(first_name, params);

          // Add your logic to handle the retrieved contact first name
        }
        this.setState({ isLoading: false });

        // Close the dropdown after switching the account
        this.hide();
      })
      .catch((error) => {
        console.error("Error switching account:", error);
        // Handle the error
      });
  };

  // handleLogout = () => {
  //   localStorage.removeItem("token");
  //   this.props.logout();
  // };

  handleLogout = async () => {
    const { accountId } = this.state;
  

    try {
      await axios.post(`${logigloUrl}/logoutUser`, { accountId });

      localStorage.removeItem("token");
      sessionStorage.removeItem("accessToken");

      this.props.logout();

      const url = "https://www.linkedin.com/m/logout";
      const win = window.open(
        url,
        "_blank",
        "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none"
      );

      setTimeout(() => {
        win.close();
        window.location.href = "/";
      }, 3000);

    } catch (error) {
      console.error('Error during logout:', error);
      alert('Logout failed.');
    }
  };

  render() {
    // const { sidebarTheme } = this.props;
    // console.log(sidebarTheme, "maj_t")

    const { submittedPostsCount, draftedPostsCount, pendingRepliesCount, pendingQuotesCount } = this.state;

    console.log(this.props, "prop test", this.state.addBusinessAccountStatus);
    const accountId = this.props.account_id;
    const { accountType, addBusinessAccountStatus } = this.state || {};

    console.log(accountType, "accccc");

    const content = (
      <TopbarDropdown>
        <UserInformation>
          <div className="userImage">
            <img src={Image} alt="user" />
            {/* <PiUserCircleThin /> */}
            {/* <FaRegUserCircle/> */}
          </div>

          {/* <div className="userDetails">
            <h3>John Doe</h3>
            <p>Sr. Marketing Officer</p>
          </div> */}
          {this.state.isLoading ? (
            <h3
              style={{
                // marginTop: "10px",
                textAlign: "center",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              Loading...
            </h3>
          ) : (
            <h3
              style={{
                marginTop: "2px",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              {this.props.name}
            </h3>
          )}

          {/* <h3 style={{marginTop : '10px', marginLeft : '10px', fontSize : '20px'}}>{this.props.name}</h3> */}
        </UserInformation>

        <SettingsList>

        
          {accountType === 'admin' || accountType === 'subadmin' ? (
            <Link
              to={`/forumDashboard/AdminSettings`}
              className="dropdownLink"
              onClick={() => this.handleAdminSettings()}
            >
              {/* <Icon>settings</Icon>
              <IntlMessages id="themeSwitcher.settings" /> */}

              <CDropdownItem href="#">
                {/* <CIcon
                  icon={cilSettings}
                  className="me-2"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                /> */}
                <IoSettingsOutline style={{marginRight: '10px'}} />

                <IntlMessages id="themeSwitcher.settings" />
              </CDropdownItem>

            </Link>
          ) : (
            
          
          
            
            <Link
              to={`/forumDashboard/UserSettings`}
              className="dropdownLink"
              onClick={() => this.handleUserSettings()}
            >
              {/* <Icon>settings</Icon> */}
              <CDropdownItem href="#" >
                {/* <CIcon
                  icon={cilSettings}
                  className="me-2"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                  
                /> */}
                <IoSettingsOutline style={{marginRight: '10px', fontSize: '20px', }} />

                <IntlMessages id="themeSwitcher.settings" />
              </CDropdownItem>
            </Link>
          )}



          {/* <Link to={`/theme`} className="dropdownLink">
            <Icon>Theme</Icon>
            <IntlMessages id="Theme" />
          </Link> */}
                    <Link
              to={`/forumDashboard/UserProfile`}
              className="dropdownLink"
              // onClick={() => this.handleUserSettings()}
            >
              {/* <Icon>settings</Icon> */}
              <CDropdownItem href="#" >
                {/* <CIcon
                  icon={cilSettings}
                  className="me-2"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                  
                /> */}
                <CiUser style={{marginRight: '10px', fontSize: '20px', }} />

                <IntlMessages id="View My Profile" />
              </CDropdownItem>
            </Link>

          <Link
            to={`/forumDashboard/drafts`}
            className="dropdownLink"
            onClick={() => this.handleDrafts()}
          >
            {/* <Icon ><RiDraftLine  /></Icon> */}

            <CDropdownItem href="#">
              {/* <CIcon
                icon={cilFile}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}
              <TfiWrite style={{marginRight: '10px', fontSize: '15px', color: 'gray'}} />

              <IntlMessages id="Drafts" />
              <CBadge color="primary" className="ms-2">
            {draftedPostsCount}
          </CBadge>
            </CDropdownItem>

            {/* <Icon>settings</Icon> */}
          </Link>
      

          <Link
            to={`/forumDashboard/submitted-posts`}
            className="dropdownLink"
            onClick={() => this.handleSubmittedPosts()}
          >
           

            <CDropdownItem href="#">
              {/* <CIcon
                icon={cilCreditCard}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}

              <BsPostcard style={{marginRight: '10px', fontSize: '15px', color: 'gray'}}  />

              <IntlMessages id="Submitted Posts"  />
              <CBadge color="warning" className="ms-2 text-dark">
              {submittedPostsCount}
          </CBadge>
            </CDropdownItem>
          </Link>

          <Link
            to={`/forumDashboard/submittedReplies`}
            className="dropdownLink"
            onClick={() => this.handleSubmittedReplies()}
          >
           

            <CDropdownItem href="#">
              {/* <CIcon
                icon={cilCreditCard}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}
                <TfiCommentAlt style={{marginRight: '10px', fontSize: '15px', color: 'gray'}}  />
              <IntlMessages id="Submitted Replies" />
              <CBadge color="warning" className="ms-2, me-2 text-dark">
              {pendingRepliesCount}
          </CBadge>
            </CDropdownItem>
          </Link>


          <Link
            to={`/forumDashboard/submittedQuotes`}
            className="dropdownLink"
            onClick={() => this.handleSubmittedQuotes()}
          >
           

            <CDropdownItem href="#">
              {/* <CIcon
                icon={cilCreditCard}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}
                <TfiCommentAlt style={{marginRight: '10px', fontSize: '15px', color: 'gray'}}  />
              <IntlMessages id="Submitted Quotes" />
              <CBadge color="warning" className="ms-2, me-2 text-dark">
              {pendingQuotesCount}
          </CBadge>
            </CDropdownItem>
          </Link>






          {/* <Link
            to={`/forumDashboard/submitted-posts`}
            className="dropdownLink"
            onClick={() => this.handleSubmittedPosts()}
          > 
          <CIcon icon={cilEnvelopeOpen} className="me-2"  style={{ color: "grey", width: "20px", height: "20px" }}/>
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </Link> */}



          {/* <a
            href="#!"
            className="dropdownLink"
            onClick={() => this.handleHelp()}
          >
            <Icon>help</Icon>
            <IntlMessages id="topbar.help" />
          </a> */}

          {/* <a
            href="#!"
            className="dropdownLink"
            onClick={() => this.handleFeedBack()}
          >
            <Icon>feedback</Icon>
            <IntlMessages id="feedback" />
          </a> */}
          {/* 
          {addBusinessAccountStatus && (
            <Link
              to={`/forumDashboard/corporateAccount?account_id=${accountId}`}
              className="dropdownLink"
              onClick={() => this.handleDrafts()}
            >
              <Icon>Add Business Account</Icon>
              <IntlMessages id="Add Business Account" />
            </Link>
          )} */}

          {/* <a
            href="#!"
            className="dropdownLink"
            onClick={() => this.handleSwitchAccount()}
          >
            <Icon>switch_account</Icon>
            <IntlMessages id="topbar.switch" />
          </a> */}




          <Link
            to="/signin"
            onClick={this.handleLogout}
            className="dropdownLink"
          >
            {/* <Icon>input</Icon>
            <IntlMessages id="topbar.logout" /> */}

            
<CDropdownItem href="#">
              {/* <CIcon
                icon={cilInput}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}

              <IoLogOutOutline style={{marginRight: '10px', fontSize: '20px', color: 'gray'}}  />

              <IntlMessages id="Logout" />
              {/* <CBadge color="primary" className="ms-2">
            42
          </CBadge> */}
            </CDropdownItem>

          </Link>
        </SettingsList>
      </TopbarDropdown>
    );
    return (
      <div id="topbarUserIcon">
        <IconButtons
          ref={(node) => {
            this.button = node;
          }}
          onClick={this.handleVisibleChange}
        >
          <div className="userImgWrapper">
            <img src={Image} alt="#" />
          </div>
        </IconButtons>

        <TopbarDropdownWrapper
          open={this.state.visible}
          anchorEl={this.state.anchorEl}
          onClose={this.hide}
          // marginThreshold={66}
          className="userPopover"
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          {content}
        </TopbarDropdownWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Map the Redux state variable to props
  name: state.Dashboard.name,
  account_id: state.Dashboard.account_id,
  ...state.App,
  customizedTheme: state.ThemeSwitcher.topbarTheme,
});

export default withRouter(
  connect(mapStateToProps, { logout, updateName })(TopbarUser)
);
