import React, { useState, useEffect, useMemo } from "react";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import Papersheet from "../../components/utility/papersheet";
import { FullColumn } from "../../components/utility/rowColumn";
import IntlMessages from "../../components/utility/intlMessages";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import { utcToZonedTime } from 'date-fns-tz';
import { parseISO, format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import axios from "axios";
import { logigloUrl } from '../Config/index'
import TopbarSearch from "../Topbar/index";
import { useDispatch, useSelector } from "react-redux";
// import { differenceInHours, formatDistanceToNow, parseISO } from "date-fns";
// import { format } from "date-fns";
// import { enUS } from "date-fns/locale";
import {
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButtonGroup,
  CButtonToolbar,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CWidgetStatsF,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardTitle,
  CCardFooter,
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import {
  cilArrowRight,
  cilBasket,
  cilBell,
  cilChartPie,
  cilMoon,
  cilLaptop,
  cilPeople,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
 
} from "@coreui/icons";
import "./index.css";
import Navbar from "../Navbar";
// import { FiEdit } from "react-icons/fi";


// User Dashboard
const UserDashboard = () => {
  const sidebarTheme = useSelector((state) => state.ThemeSwitcher.sidebarTheme);
  const postTextColor = useSelector((state) => state.ThemeSwitcher.postTextTheme.textColor);
  
  const searchText = useSelector((state) => state.Dashboard.searchText);
  const account_name = useSelector((state) => state.Dashboard.name);
  // const check = useSelector((state) => state.Dashboard);
  console.log(postTextColor, "dashboard_page");



  const history = useHistory();
  // const name = useSelector((state) => state.Dashboard.name);

 


  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  // const account_id = new URLSearchParams(location.search).get("account_id");
  // const account_id_number = parseInt(account_id, 10);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedText, setUpdatedText] = useState("");
  const [updatedBusAccId, setUpdatedBusAccId] = useState("");
  const [updatingPostId, setUpdatingPostId] = useState(null);
  const [latestFilter, setLatestFilter] = useState(true);
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [postReplyCounts, setPostReplyCounts] = useState({});
  const token = useSelector((state) => state.Auth.idToken);
  const jwtToken = localStorage.getItem("token");
  const userAccount_id = localStorage.getItem("token");
  const tokenObject = JSON.parse(userAccount_id);
  const account_id = tokenObject.accountId 
  console.log(tokenObject.accountId, "account_id_from_c")
  const currentDate = new Date().toISOString().slice(0, 10);
 


  



  const [totalNumOfPosts, setTotalNumOfPosts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Same as the limit set on the backend
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState("all");
  const [addsData, setAddsData] = useState([]);

  const [postTitleColor, setpostTitleColor] = useState(null);
  const [postDscriptionColor, setPostDescriptionColor] = useState(null);
  const [activeExperts, setActiveExperts] = useState([]);

  const [userCounts, setUserCounts] = useState({
    totalUserCount: 0,
    onlineUserCount: 0,
  });
  
  const postHeaderColor = "green";
  const descriptionColorName = "red";

  const totalLoginCount = activeExperts.reduce((sum, expert) => sum + expert.login_count, 0);

 


  


//   // Retrieve the string representation from local storage
// const themeData = localStorage.getItem('themeData');

// // Parse the string back into an object
// const parsedThemeData = JSON.parse(themeData);

// // Access postTextTheme from parsedThemeData
// const postTextTheme = parsedThemeData.postTextTheme;

// // Access backgroundColor property from postTextTheme
// const postTextColorHexCode = postTextTheme.backgroundColor;




  // useEffect(() => {
  //   axios
  //   .get(`${logigloUrl}/getAllPostsData?page=${}`)
  //     .then((response) => {
  //       console.log(response.data, "ppp")
  //       setPosts(response.data.reverse()); // Store the retrieved data in state

  //       // Fetch category names for each post
  //       // const postIds = response.data.map((post) => post.id);
  //       // debugger;
  //       // fetchCategoryNames(postIds);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);




  useEffect(() => {
    // Fetch existing settings from the backend if necessary
    axios
      .get(`${logigloUrl}/getNotificationSettings`, { params: { accountId: account_id } })
      .then((response) => {
        const data = response.data;
        const settings = {
          postCreation: data.post_creation === 1,
          postModeration: data.post_moderation === 1,
          postRejection: data.post_rejection === 1,
          replyCreation: data.reply_creation === 1,
          commentCreation: data.comment_creation === 1,
        };
       
        localStorage.setItem("notificationSettings", JSON.stringify(settings));
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching notification settings:", error);
      });
  }, [account_id]);


   // advertisements API
   useEffect(() => {
    // Fetch data from the backend
    axios
      .get(`${logigloUrl}/advertisementData`)
      .then((response) => {
        setAddsData(response.data);
        console.log(response.data, "adds");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  useEffect(() => {
    // Fetch data from the backend
    axios
    .get(`${logigloUrl}/getColorByName/${postHeaderColor}/${descriptionColorName}`)
      .then((response) => {
        const colors = response.data.colors;
       ;

        const postHeaderColor = colors.find(color => color.type === 'postHeader')
        const descriptionColor = colors.find(color => color.type === 'description');

        setpostTitleColor(postHeaderColor.hex_code);
        setPostDescriptionColor(descriptionColor.hex_code)

        console.log(response.data.colors, "color");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [descriptionColorName, postHeaderColor]); // Include colorName in the dependency array to trigger the effect when colorName changes





  const fetchData = async (page) => {
    const pageNumber = isNaN(page) ? 1 : parseInt(page);
  
    try {
      const url =
        filterType === "my"
          ? `${logigloUrl}/getUserPostsData/${account_id}/${account_name}?page=${pageNumber}`
          : `${logigloUrl}/getAllPostsData?page=${pageNumber}`;
  
      const response = await axios.get(url);
  
      setPosts(response.data.posts);
      setTotalPages(Math.ceil(response.data.totalCount / postsPerPage));
      setTotalNumOfPosts(response.data.totalCount)

      console.log(posts, "pagination_posts")
      
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filterType]);
  


   // Endpoint to fetch data totalUsers and onlineUsers Count
   useEffect(() => {
    const fetchUserCounts = () => {
      axios
        .get(`${logigloUrl}/userCounts`)
        .then((response) => {
          setUserCounts(response.data);
        })
        .catch((error) => {
          console.error({ "error message": error });
        });
    };

    // Fetch initially when component mounts
    fetchUserCounts();

    // Set interval to fetch user counts every 5 seconds
    const intervalId = setInterval(fetchUserCounts, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

 
  // useEffect(() => {
  //   fetchData(currentPage);
  // }, [currentPage, filterType]);

  const handleFilterChange = (filter) => {
    setFilterType(filter);
    setCurrentPage(1);
  };
  

  


const parsedToken = JSON.parse(localStorage.getItem('token'));
// Log the accountType
// const accountType = parsedToken.accountType;


// console.log(accountType, "acc_type")
 

  // Function to format date and time efficiently
  // const formatDateTime = (dateString) => {
  //   const dateObject = new Date(dateString);
  //   return format(dateObject, "dd/MM/yyyy, hh:mm:ss a", { locale: enUS });
  // };

  

 



  // Function to handle the filter posts to Latest posts
  const handleLatestFilter = (event) => {
    setLatestFilter(true); // Always set the Latest filter to true when "Latest" is clicked
    setAscendingOrder(false); // Set ascendingOrder to false to display in descending order
  };

  // Function to handle the filter posts to Oldest posts
  const handleOldestFilter = (event) => {
    setLatestFilter(false); // Always set the Latest filter to false when "Oldest" is clicked
    setAscendingOrder((prevAscendingOrder) => !prevAscendingOrder); // Toggle ascendingOrder value
    console.log(ascendingOrder);
  };

  // Function to handle navigatation to create post container
  const onClickCreatePostBtn = () => {
    history.push(`/forumDashboard/createPost`);
  };

  // Function to handle cancel update post
  const handleCancelUpdatePost = () => {
    console.log("handleCancelUpdatePost triggered");
    setShowUpdatePrompt(false);
    setUpdatingPostId(null);
    setUpdatedTitle("");
    setUpdatedText("");
    setUpdatedBusAccId("");
  };

  // Function to handle update post
  const handleSaveUpdatePost = () => {
    console.log("qwertyui");
    // Ensure at least one field is updated
    if (!updatedTitle.trim() && !updatedText.trim()) {
      alert("Please enter at least one updated field.");
      return;
    } else if (updatedTitle === "") {
      alert("Title shound not empty.");
    } else if (updatedText === "") {
      alert("Text shound not empty.");
    } else {
      // Make the API call to update the post in the database
      axios
        .put(`${logigloUrl}/updatePost/${updatingPostId}`, {
          account_id: account_id,
          bus_acc_id: 1111,
          title: updatedTitle,
          text: updatedText,
          created_at: currentDate,
          last_modified: currentDate,
          last_modified_by: account_id,
          last_mod_by_bus: 1111,
        })
        .then((response) => {
          if (response.status === 200) {
            // If the post was updated successfully, update the posts list to show the updated post
            setPosts((prevPosts) =>
              prevPosts.map((post) =>
                post.id === updatingPostId
                  ? {
                      ...post,
                      bus_acc_id: updatedBusAccId,
                      title: updatedTitle,
                      text: updatedText,
                    }
                  : post
              )
            );
            // Close the update prompt
            setShowUpdatePrompt(false);
            setUpdatingPostId(null);
            setUpdatedTitle("");
            setUpdatedText("");
            setUpdatedBusAccId("");
          } else {
            console.error("Error updating post");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

 
  const getCurrentUserPosts = () => {
    console.log(account_name, "l");
    axios
      .get(`${logigloUrl}/getUserPostsData/${account_id}/${account_name}`)
      .then((response) => {
        setPosts(response.data);
        console.log(response.data, "user_posts");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // const getTimeDisplayText = (postCreatedAt) => {
  //   const createdTime = parseISO(postCreatedAt);
  //   const indianTimeZone = 'Asia/Kolkata'; // India time zone
  //   const createdTimeInIndianTimeZone = utcToZonedTime(createdTime, indianTimeZone);
  //   const currentTime = new Date();
  
  //   const timeDifferenceInSeconds = Math.floor(
  //     (currentTime - createdTimeInIndianTimeZone) / 1000
  //   );

  //   if (timeDifferenceInSeconds < 60) {
  //     // If post is created within the last minute, display "X seconds ago"
  //     return ` ${timeDifferenceInSeconds} second${
  //       timeDifferenceInSeconds !== 1 ? "s" : ""
  //     } ago`;
  //   } else if (timeDifferenceInSeconds < 3600) {
  //     // If post is created within the last hour, display "X minutes ago"
  //     const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
  //     return ` ${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  //   } else if (
  //     timeDifferenceInSeconds >= 3600 &&
  //     timeDifferenceInSeconds < 86400
  //   ) {
  //     // If post is created more than an hour ago, display "X hours ago"
  //     const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
  //     const remainingMinutes = Math.floor(
  //       (timeDifferenceInSeconds % 3600) / 60
  //     );
  //     return ` ${hoursAgo} hour${
  //       hoursAgo !== 1 ? "s" : ""
  //     } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""} ago`;
  //   } else {
  //     // If post is created more than 1 day ago, display formatted date and time
  //     return formatDateTime(postCreatedAt);
  //   }
  // };




  // const getTimeDisplayText = (postCreatedAt) => {
  //   const createdTime = parseISO(postCreatedAt);
  //   const indianTimeZone = 'Asia/Kolkata'; // India time zone
  //   const currentTime = new Date();
  
  //   // Convert createdTime to Indian time zone
  //   const createdTimeInIndianTimeZone = utcToZonedTime(createdTime, indianTimeZone);
  
  //   const timeDifferenceInSeconds = differenceInSeconds(currentTime, createdTimeInIndianTimeZone);
  //   const timeDifferenceInMinutes = differenceInMinutes(currentTime, createdTimeInIndianTimeZone);
  //   const timeDifferenceInHours = differenceInHours(currentTime, createdTimeInIndianTimeZone);
  //   const timeDifferenceInDays = differenceInDays(currentTime, createdTimeInIndianTimeZone);
  
  //   if (timeDifferenceInSeconds < 60) {
  //     // If post is created within the last minute, display "X seconds ago"
  //     return `${timeDifferenceInSeconds} second${timeDifferenceInSeconds !== 1 ? 's' : ''} ago`;
  //   } else if (timeDifferenceInMinutes < 60) {
  //     // If post is created within the last hour, display "X minutes ago"
  //     return `${timeDifferenceInMinutes} minute${timeDifferenceInMinutes !== 1 ? 's' : ''} ago`;
  //   } else if (timeDifferenceInHours < 24) {
  //     // If post is created within the last day, display "X hours ago"
  //     return `${timeDifferenceInHours} hour${timeDifferenceInHours !== 1 ? 's' : ''} ago`;
  //   } else {
  //     // If post is created more than 1 day ago, display formatted date and time
  //     return format(createdTimeInIndianTimeZone, 'MM/dd/yyyy, hh:mm:ss a');
  //   }
  // };


  const getTimeDisplayText = (postCreatedAt) => {
    const createdTime = parseISO(postCreatedAt);
    const indianTimeZone = 'Asia/Kolkata'; // India time zone
    const currentTime = new Date();
  
    // Convert createdTime to Indian time zone
    const createdTimeInIndianTimeZone = utcToZonedTime(createdTime, indianTimeZone);
  
    const timeDifferenceInSeconds = differenceInSeconds(currentTime, createdTimeInIndianTimeZone);
    const timeDifferenceInMinutes = differenceInMinutes(currentTime, createdTimeInIndianTimeZone);
    const timeDifferenceInHours = differenceInHours(currentTime, createdTimeInIndianTimeZone);
    const timeDifferenceInDays = differenceInDays(currentTime, createdTimeInIndianTimeZone);
  
    if (timeDifferenceInSeconds < 60) {
      // If post is created within the last minute, display "X seconds ago"
      return `${timeDifferenceInSeconds} second${timeDifferenceInSeconds !== 1 ? 's' : ''} ago`;
    } else if (timeDifferenceInMinutes < 60) {
      // If post is created within the last hour, display "X minutes ago"
      return `${timeDifferenceInMinutes} minute${timeDifferenceInMinutes !== 1 ? 's' : ''} ago`;
    } else if (timeDifferenceInHours < 24) {
      // If post is created within the last day, display "X hours and Y minutes ago"
      const remainingMinutes = timeDifferenceInMinutes - (timeDifferenceInHours * 60);
      return `${timeDifferenceInHours} hour${timeDifferenceInHours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''} ago`;
    } else {
      // If post is created more than 1 day ago, display formatted date and time
      return format(createdTimeInIndianTimeZone, 'MM/dd/yyyy, hh:mm:ss a');
    }
  };


  // Function to get the first n words from the post Text
  const post_text_to_display = 15;
  const getFirstNWords = (text, n) => {
    const words = text.split(" ");
    const firstNWords = words.slice(0, n).join(" ");
    return firstNWords + (words.length > n ? "..." : ""); // Add "..." if there are more words in the text
  };

  const getAllPosts = () => {
    
    axios
      .get(`${logigloUrl}/getAllPostsData?page=${currentPage}`)
      .then((response) => {
        setPosts(response.data.posts.reverse());
        setTotalPages(Math.ceil(response.data.totalCount / postsPerPage));
        setTotalNumOfPosts(response.data.totalCount)
        console.log(response.data.posts)
      })
      .catch((error) => {
        console.error(error);
      });
  };

//   const getCurrentUserPosts = (page) => {
//     const pageNumber = isNaN(page) ? 1 : parseInt(page);
//     axios
//     .get(`${logigloUrl}/getUserPostsData/${account_id}/${account_name}?page=${pageNumber}`)
//     .then((response) => {
//       setPosts(response.data.posts);
//       setTotalPages(Math.ceil(response.data.totalCount / postsPerPage));
//       console.log(response.data.posts, "user_posts")
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };

  // Trigger the effect whenever currentPage changes
  // useEffect(() => {
  //   getCurrentUserPosts(currentPage);
  // }, [currentPage]);


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Code to navigate to the "/search" route
      history.push(`/forumDashboard/searchPage?q=${e.target.value}`, "_blank");
    }
  };
 

  const storePostDetails = (post) => {
    // Convert post object to string
    const postString = JSON.stringify(post);
    
    // Store post details in local storage
    localStorage.setItem('selectedPost', postString);
  };

 



  


  // If User is not logged following condition will redirected to landing page
  if (!jwtToken) {
    return <Redirect to="/" />;
  }





  return (
    <LayoutWrapper>
      <FullColumn>
      <div className="posts-con" >
        <div className="forum-dashboars-con">
              <div className="forum-content-top-container">

              <div className="forum-dashboard-global-search-outer-con">
            <div className="forum-home-global-search-con">
              <SearchFavoriteIcon />
              
              <input
                className="forum-global-search-input"
                placeholder="Search"
                onKeyPress={handleKeyPress}
               
              />
            </div>
           
          </div>

          

          {/* <CButton
                  className="post-creation-btn"
                  onClick={onClickCreatePostBtn}
                >
                  Create Post
                  </CButton> */}

                {/* <div className="dashboard-filters-and-posts-count-con">
                  <p className="total-posts-text">{totalNumOfPosts} Posts</p>
                 
                  <CButtonGroup className="me-0" role="group" aria-label="First group">
                  <CButton color="secondary" variant="outline" onClick={() => handleFilterChange("all")}>
                    All Posts
                  </CButton>
                  <CButton color="secondary" variant="outline" onClick={() => handleFilterChange("my")}>
                  My Posts
                  </CButton>
                  <CButton color="secondary" variant="outline" onClick={handleLatestFilter}>
                  Latest
                  </CButton>
                  <CButton color="secondary" variant="outline" onClick={handleOldestFilter}>
                  Oldest
                  </CButton>
                 
                </CButtonGroup>
                </div> */}

                

              </div>
<div className="user-dashboard-adds-con"> 



            
              {addsData.map((item) => {
                if (item.type === "center" && item.render) {
                  return (
                    <div key={item.id} >
                      <p>{item.title}</p>
                      {/* Add other elements like image or description if needed */}
                    </div>
                  );
                }
                return null;
              })}

</div>
             
            </div>

            <div className="forum-adds-con">


            {/* <CCol style={{ marginTop: "10px", width: "100%" }}>
                    <CWidgetStatsF
                      value={userCounts.totalUserCount}
                      icon={<CIcon width={24} icon={cilUser} size="xl" />}
                      title="Total Members"
                      color="info"
                    />
                  </CCol>

                  <CCol style={{ marginTop: "10px", width: "100%"  }}>
                    <CWidgetStatsF
                      icon={<CIcon width={24} icon={cilLaptop} size="xl" />}
                      title="Users Online"
                      value={userCounts.onlineUserCount}
                      color="warning"
                    />
                  </CCol>

                  <CCol style={{ marginTop: "10px", width: "100%" }}>
                    <CWidgetStatsF
                      icon={<CIcon width={24} icon={cilBell} size="xl" />}
                      title="Total Posts"
                      value={totalNumOfPosts}
                      color="primary"
                    />
                  </CCol> */}
             
             <CCard style={{ marginTop: "10px", width: "100%" }}>
                   
                    <CCardBody>
                      <CCardTitle>Members online</CCardTitle>
                     
                      <small style={{fontSize: '12px'}}>
                        {/* Total Posts:  {totalNumOfPosts} <br/> */}
                        {userCounts.onlineUserCount === 0 ?  
                        ( 'No members online now') : 
                        ( `Members online: ${userCounts.onlineUserCount}`)}
                        
                      </small>
                    </CCardBody>
                    <CCardFooter>
                      <small style={{fontSize: '12px'}} className="text-body-secondary">Total: (Members: {userCounts.totalUserCount}, All logins: {totalLoginCount})</small>
                    </CCardFooter>
                  </CCard>



                  <div className="forum-right-adds-con">
                    <img style={{width: '100%', }} src="https://res.cloudinary.com/dwwzfhucu/image/upload/v1718262493/poster_bspojt.jpg" />
                  </div>
                  
            {addsData.map((item) => {
                if (item.type === "right" && item.render) {
                  return (
                    <div key={item.id} className="forum-right-adds-con">
                     <img
                        src={item.image_url}
                        className="ad-image-styles"
                      />
                      <h5>{item.title}</h5>
                      <p>{item.text}</p>
                    </div>
                  );
                }
                return null;
              })}
           

              <div className="forum-right-side-adds-con">
                <h5>Priority Questions</h5>
                <p className="forum-adds-text">
                  Need supplier of fleet to carry 1K Tons of perishable goods
                  priority
                </p>
                <p className="forum-adds-text">
                  Urgent requirement of Pharmaceutical goods logistics vendor
                </p>
              </div>
            </div>
        </div>
      </FullColumn>
    </LayoutWrapper>
  );
};

export default UserDashboard;
