import React, { useEffect, useState } from "react";
import "./index.css";
import { RiFlag2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { logigloUrl } from "../Config";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState("Posts");
  const [userData, setUserData] = useState();
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const account = localStorage.getItem("token");
  const parsedAccount = JSON.parse(account);

  const accountId = parsedAccount.accountId;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleFlagModal = () => {
    setIsFlagModalOpen(!isFlagModalOpen);
  };
  const fetchUserData = async () => {
    try {
      const data = await fetch(
        `${logigloUrl}/userAccountData/${accountId}`
        // "http://test.logiglo.com/api/userAccountData/158"
      );
      const totaldata = await data.json();
      setUserData(totaldata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const formatDate = (dateString) => {
    const zonedTime = utcToZonedTime(dateString, "Asia/Kolkata"); // Convert to IST time zone
    return format(zonedTime, "MMMM d, yyyy hh:mm a 'IST'"); // Format the date
  };

  const createMarkup = (html) => {
    return { __html: html };
  };
  

  const renderTabContent = () => {
    try {
      switch (activeTab) {
        case "Posts":
          return userData?.posts && userData.posts.length > 0 ? (
            userData.posts.map((post) => (
              <div key={post.post_id} className="related-tab-item">
                <h5>{post.text}</h5>
                <p>{post.title}</p>
                <p className="date">Posted on: {formatDate(post.created_at)}</p>
              </div>
            ))
          ) : (
            <p className="no-posts-message">No posts were found</p>
          );

        case "Comments":
          return userData?.comments && userData.comments.length > 0 ? (
            userData.comments.map((comment, index) => {
              // const post = userData.posts.find((post) => post.post_id === comment.post_id);
              return (
                <div key={index} className="related-tab-item">
                  <h5>{comment.post_title}</h5>
                  <p>{comment.text}</p>
                  {/* {post && (
                    <p className="post-info">
                      <strong>Commented on Post:</strong> {post.title}
                    </p>
                  )} */}
                  <p className="date">
                    Commented on: {formatDate(comment.created_at)}
                  </p>
                </div>
              );
            })
          ) : (
            <p>No comments were found</p>
          );

        case "Replies":
          return userData?.replies && userData.replies.length > 0 ? (
            userData.replies.map((reply) => {
              // const post = userData.posts.find((post) => post.post_id === reply.post_id);
              return (
                <div key={reply.reply_id} className="related-tab-item">
                  <h5>{reply.post_title}</h5>
                  <p dangerouslySetInnerHTML={createMarkup(reply.text)}></p>
                  {/* {post && (
                    <p className="post-info">
                      <strong>Replied to Post:</strong> {post.title}
                    </p>
                  )} */}
                  <p className="date">
                    Replied on: {formatDate(reply.created_at)}
                  </p>
                </div>
              );
            })
          ) : (
            <p>No replies were found</p>
          );

        // case "Subscriptions":
        //   return userData?.subscriptions &&
        //     userData.subscriptions.length > 0 ? (
        //     userData.subscriptions.map((subscription, index) => (
        //       <div key={index} className="subscription-item">
        //         <p>{subscription.name}</p>
        //         <p>
        //           Subscribed on:{" "}
        //           {new Date(subscription.date).toLocaleDateString()}
        //         </p>
        //       </div>
        //     ))
        //   ) : (
        //     <p>No subscriptions were found</p>
        //   );

        case "Helpful reply":
          return userData?.helpful_replies &&
            userData.helpful_replies.length > 0 ? (
            userData.helpful_replies.map((reply, index) => {
              const post = userData.posts.find(
                (post) => post.post_id === reply.post_id
              );
              return (
                <div key={index} className="helpful-reply-item">
                  <p>{reply.text}</p>
                  {post && (
                    <p className="post-info">
                      <strong>Helpful reply to Post:</strong> {post.title}
                    </p>
                  )}
                  <p>Replied on: {new Date(reply.date).toLocaleDateString()}</p>
                </div>
              );
            })
          ) : (
            <p>No helpful replies were found</p>
          );

        default:
          // Handle unknown or unsupported tabs
          return <p>Invalid Tab</p>;
      }
    } catch (error) {
      // Handle any unexpected errors during the switch
      console.error("Error in tab content rendering:", error);
      return <p>Error occurred while loading content</p>;
    }
  };

  return (
    <div className="profile-container">
      {/* Top Header Section */}
      <div className="top-header">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="breadcrumb">Home</div>
          <div className="user-display-name">{userData?.first_name}</div>
        </div>
        <div>
          {/* <button className="flag-button" onClick={toggleFlagModal}>
            <RiFlag2Line />
          </button> */}
          <Link to={`/forumDashboard/UserSettings`}>
            <button className="edit-profile-btn">Edit Profile</button>
          </Link>
        </div>
      </div>

      <div className="content-wrapper">
        {/* Sidebar Section */}
        <div className="sidebar">
          <div className="profile-info">
            <img
              src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"
              alt="User"
              className="user-image"
            />
            <h5 className="user-name">@{userData?.first_name}</h5>
          </div>
        </div>

        {/* Main Content Section */}
        <div className="main-content">
          {/* Profile States Section */}
          <div className="profile-stats">
            <div className="stat-item">
              <div className="stat-value">{userData?.number_of_posts}</div>
              <div className="stat-label">Posts</div>
            </div>
            <div className="stat-item">
              <div className="stat-value">{userData?.number_of_replies}</div>
              <div className="stat-label">Replies</div>
            </div>
            <div className="stat-item">
              <div className="stat-value">{userData?.number_of_comments}</div>
              <div className="stat-label">Comments</div>
            </div>

            {/* <div className="stat-item">
              <div className="stat-value">{userData?.subscriptions}</div>
              <div className="stat-label">Subscriptions</div>
            </div> */}
            <div className="stat-item">
              <div className="stat-value">{userData?.helpful_replies}</div>
              <div className="stat-label">Helpful reply</div>
            </div>
          </div>

          {/* Tabs Section */}

          <div className="tabs">
            {[
              "Posts",
              "Replies",
              "Comments",
              // "Subscriptions",
              "Helpful reply",
            ].map((tab) => (
              <div
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          {/* Tab Content */}

          <div className="tab-content">{renderTabContent()}</div>
        </div>
      </div>

      {/* Flag Form Modal */}
      {isFlagModalOpen && (
        <div className="flag-modal">
          <div className="flag-modal-content">
            <h4>Submit a Flag</h4>
            <form>
              <label>Type:</label>
              <select required>
                <option value="">Select type</option>
                <option value="Scams">Scams and/or fraud</option>
                <option value="Harassment">Harassment</option>
                <option value="Other">Other</option>
              </select>

              <label>Description:</label>
              <textarea placeholder="Describe the issue..." required />

              <label>
                <input type="checkbox" required className="flag-check-box" />I
                confirm, to the best of my knowledge, that the information I am
                providing is accurate and complete.
              </label>
              <div className="flag-modal-actions">
                <button type="button" onClick={toggleFlagModal}>
                  Cancel
                </button>
                <button type="submit">Flag</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
