import React, { useState, useEffect } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logigloUrl } from "../../../src/containers/Config";
import axios from "axios";
import { CFormCheck, CFormLabel } from "@coreui/react";
import UserStatistics from "../userStatistics";
import "./index.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  const history = useHistory();

  useEffect(() => {
    axios.get(`${logigloUrl}/forumCategories`).then((response) => {
      setCategories(response.data);
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = useState({
    transport_mode: null,
    postType: null,
    service: null,
    postOrigin: null,
    shipment: null,
  });

  const handleOptionChange = (categoryName, selectedOption) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [categoryName]: selectedOption,
    }));
  };

  const handleClick = (category, option) => {
    // console.log("Category yes")
    setSelectedOptions((prev) => ({
      ...prev,
      [category]: option,
    }));
    history.push(`/forumDashboard/category/${category}/${option}`);
    // console.log(`Selected ${category}:`, option);
  };

  return (
    <div className="categories-main-comp">

      {/* user Stats */}
      <UserStatistics/>

      <div className="categories-inner-comp">
        <h4 className="category-header">Posts for different Categories</h4>
        {categories.map((category, displayIndex) => (
          <div
            key={category.id}
            className={`category-container ${
              displayIndex % 2 === 0
                ? "each-category-container-odd"
                : "each-category-container-even"
            }`}
            
          >
            <h4 className="category-sub-header">{category.name}</h4>
            <div>
              {category.options.map((option) => (
                <div key={option.id}>
                  <ul className="category-items-list">
                    <li key={category.id} 
                     onClick={() =>
                        handleClick(
                          category.name === "Quote Enquiry"
                            ? "transport_mode"
                            : category.name === "General Enquiry"
                            ? "transport_mode"
                            : category.name === "General Discussion"
                            ? "general_discussion"
                            : category.name === "Supply Chain Management" 
                            ? "supply_chain_management"
                            : category.name === "Industry Verticals" 
                            ? "Industry_verticals"
                            : category.name === "Technologies & Innovations" 
                            ? "technologies_Innovations"
                            : category.name === "Sustainability & CSR" 
                            ? "sustainability_CSR"
                            : category.name === "Regulations & Compliance" 
                            ? "regulations_compliance"
                            : category.name === "Professional Development" 
                            ? "professional_development"
                            : category.name,
                          option.label
                        )
                      }
                      >
                     {option.label}
                     </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
   
      
    </div>
  );
};

export default Categories;
